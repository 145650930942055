import { IControllerConfig } from '@wix/yoshi-flow-editor';
import {
  BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID,
  BOOKINGS_MAIN_PAGE_PRESET_ID,
} from '../../consts';

export const onLocationURLChange = (wixCodeApi: any, callback: Function) => {
  const path = wixCodeApi.location.path;
  wixCodeApi.location.onChange((data: { path: string[] }) => {
    if (data.path[0] === path[0]) {
      callback();
    }
  });
};

export const getPresetId = (
  config: IControllerConfig,
  isEditorX: boolean,
): string =>
  config.publicData.COMPONENT?.presetId ||
  (isEditorX
    ? BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID
    : BOOKINGS_MAIN_PAGE_PRESET_ID);
