import {
  CatalogServiceDto,
  ReservedLocationIds,
} from '@wix/bookings-uou-types';
import { ALL_SERVICES } from '../../consts';
import {
  FilterOption,
  FilterServicesByOptions,
  Resources,
} from '../../types/types';
import settingsParams from '../../components/BookOnline/settingsParams';
import { bulkCreateAnywhereUrl } from '@wix/wix-anywhere-api';

export const getServices = async ({
  filterOptions,
  filteredResources,
  settings,
  isAnywhereFlow = false,
}: {
  filterOptions: FilterOption[];
  filteredResources: Resources;
  settings: any;
  isAnywhereFlow?: boolean;
}): Promise<CatalogServiceDto[]> => {
  if (
    !filteredResources.offerings ||
    filteredResources.offerings.length === 0
  ) {
    return [];
  }
  const services = isAnywhereFlow
    ? await Promise.all(
        filteredResources.offerings.map(async (offering) => {
          offering.fullUrl = (
            await bulkCreateAnywhereUrl({
              urls: [offering.path],
              baseUrl: offering.baseUrl,
            })
          )[0];
          return offering;
        }),
      )
    : filteredResources.offerings;
  const selectedFilterId = filterOptions.find(
    ({ isSelected }: FilterOption) => isSelected,
  )?.id;

  if (
    !selectedFilterId ||
    selectedFilterId === ALL_SERVICES ||
    !settings.get(settingsParams.isListFilterVisible)
  ) {
    return services;
  }

  const filterServicesBy = settings.get(settingsParams.filterServicesBy);
  if (filterServicesBy === FilterServicesByOptions.CATEGORIES) {
    return filterServicesByCategoryId(services, selectedFilterId);
  }
  return filterServicesByLocationId(services, selectedFilterId);
};

export const filterServicesByCategoryId = (
  services: CatalogServiceDto[],
  categoryId: string,
): CatalogServiceDto[] => {
  return services.filter((service) => service.categoryId === categoryId);
};

export const filterServicesByLocationId = (
  services: CatalogServiceDto[],
  locationId: string,
): CatalogServiceDto[] => {
  if (locationId === ReservedLocationIds.OTHER_LOCATIONS) {
    return services.filter((service) =>
      service.info.locations?.find(
        (location) => location.type !== 'OWNER_BUSINESS',
      ),
    );
  }
  return services.filter((service) =>
    service.info.locations?.find(
      (location) => location.businessLocation?.id === locationId,
    ),
  );
};
