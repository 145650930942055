import { NavigateToNextPageAction } from '../navigateToNextPage/navigateToNextPage';

import { CatalogServiceDto } from '@wix/bookings-uou-types';
import { ReferralInfo } from '../../utils/bi/consts';
import { NavigationTarget } from '../navigateToNextPage/consts';

export type OnMoreInfoClickAction = ({
  service,
  referralInfo,
}: {
  service: CatalogServiceDto;
  referralInfo: ReferralInfo;
}) => Promise<void>;

export const createOnMoreInfoClickAction = ({
  navigateToNextPage,
}: {
  navigateToNextPage: NavigateToNextPageAction;
}): OnMoreInfoClickAction => {
  return async ({
    service,
    referralInfo,
  }: {
    service: CatalogServiceDto;
    referralInfo: ReferralInfo;
  }) => {
    return navigateToNextPage({
      target: NavigationTarget.SERVICE_PAGE,
      service,
      referralInfo,
    });
  };
};
