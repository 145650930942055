import { ServiceType } from '@wix/bookings-uou-types';
import { ServiceIntent } from '../../utils/navigation/navigation.const';
import {
  HandelNavigationAction,
  NavigationAction,
} from '../handleNavigation/handleNavigation';

export const createNavigateToBookPageAction = (
  handleNavigation: HandelNavigationAction,
): NavigationAction => {
  return (service, referralInfo) => {
    const intent =
      service.schedulePolicy.displayOnlyNoBookFlow ||
      service.type === ServiceType.COURSE
        ? ServiceIntent.SHOW_DETAILS
        : ServiceIntent.BOOK_OFFERING;
    handleNavigation({ service, intent, referralInfo });
  };
};
