import { ServiceIntent } from '../../utils/navigation/navigation.const';
import {
  HandelNavigationAction,
  NavigationAction,
} from '../handleNavigation/handleNavigation';

export const createNavigateToServicePageAction = (
  handleNavigation: HandelNavigationAction,
): NavigationAction => {
  return (service, referralInfo) => {
    handleNavigation({
      service,
      intent: ServiceIntent.SHOW_DETAILS,
      referralInfo,
    });
  };
};
