import { FilterOption } from '../../types/types';
import settingsParams from '../../components/BookOnline/settingsParams';

export type HeaderViewModel = {
  isListFilterVisible: boolean;
};

export const createHeaderViewModel = (
  settings: any,
  filterOptions: FilterOption[],
): HeaderViewModel | undefined => {
  const isListFilterVisible =
    settings.get(settingsParams.isListFilterVisible) &&
    filterOptions.length > 1;

  return {
    isListFilterVisible,
  };
};
