import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import { BookingsApi } from '../api/BookingsApi';
import { WidgetConfig } from '../../legacy/types';
import { Resources } from '../types/types';
import { WidgetViewModel } from '../viewModel/viewModel';
import {
  createOnFilterOptionSelectedAction,
  OnFilterOptionSelectedAction,
} from './onFilterOptionSelected/onFilterOptionSelected';
import {
  createOnDialogCloseAction,
  OnDialogCloseAction,
} from './onDialogClose/onDialogClose';
import {
  createHandelNavigationAction,
  NavigationAction,
} from './handleNavigation/handleNavigation';
import { createNavigateToServicePageAction } from './navigateToServicePage/navigateToServicePage';
import { createNavigateToBookPageAction } from './navigateToBookPage/navigateToBookPage';
import {
  CourseAvailabilityAction,
  createCourseAvailabilityAction,
} from './courseAvailability/courseAvailability';
import { createNavigateToPricingPlanAction } from './navigateToPricingPlans/navigateToPricingPlans';
import { createSecondaryNavigationAction } from './secondaryNavigation/secondaryNavigation';
import {
  createOnPreferencesModalServiceSelectedAction,
  OnPreferencesModalServiceSelected,
} from './onPreferencesModalServiceSelected/onPreferencesModalServiceSelected';
import {
  createNavigateToNextPageAction,
  NavigateToNextPageAction,
} from './navigateToNextPage/navigateToNextPage';
import {
  createOnServiceClickAction,
  OnServiceClickAction,
} from './onServiceClick/onServiceClick';
import { createOnBookFlowStartAttemptAction } from './onBookFlowStartAttempt/onBookFlowStartAttempt';
import { createOpenServicesPreferencesModalAction } from './openServicesPreferencesModal/openServicesPreferencesModal';
import { createOpenPremiumDialogAction } from './openPremiumDialog/openPremiumDialog';
import {
  createOnMoreInfoClickAction,
  OnMoreInfoClickAction,
} from './onMoreInfoClick/onMoreInfoClick';
import {
  createOnActionButtonClickAction,
  OnActionButtonClickAction,
} from './onActionButtonClick/onActionButtonClick';

export type WidgetActions = {
  onFilterOptionSelected: OnFilterOptionSelectedAction;
  onDialogClose: OnDialogCloseAction;
  navigateToServicePage: NavigationAction;
  navigateToBookPage: NavigationAction;
  secondaryNavigation: NavigationAction;
  getCourseAvailability: CourseAvailabilityAction;
  navigateToPricingPlan: NavigationAction;
  onPreferencesModalServiceSelected: OnPreferencesModalServiceSelected;
  onServiceClick: OnServiceClickAction;
  onMoreInfoClick: OnMoreInfoClickAction;
  onActionButtonClick: OnActionButtonClickAction;
  navigateToNextPage: NavigateToNextPageAction;
};

export const createWidgetActions = ({
  widgetViewModel,
  filteredResources,
  wixSdkAdapter,
  bookingsApi,
  flowAPI,
  config,
  setProps,
}: {
  widgetViewModel: WidgetViewModel;
  filteredResources: Resources;
  wixSdkAdapter: WixOOISDKAdapter;
  bookingsApi: BookingsApi;
  flowAPI: ControllerFlowAPI;
  config: WidgetConfig;
  setProps: Function;
}): WidgetActions => {
  const onFilterOptionSelected = createOnFilterOptionSelectedAction({
    widgetViewModel,
    filteredResources,
    wixSdkAdapter,
    setProps,
    flowAPI,
  });

  const onDialogClose = createOnDialogCloseAction({
    widgetViewModel,
    setProps,
    flowAPI,
  });

  const handleNavigation = createHandelNavigationAction({
    widgetViewModel,
    wixSdkAdapter,
    bookingsApi,
    flowAPI,
    config,
    setProps,
  });

  const navigateToServicePage =
    createNavigateToServicePageAction(handleNavigation);
  const navigateToBookPage = createNavigateToBookPageAction(handleNavigation);
  const secondaryNavigation = createSecondaryNavigationAction(
    flowAPI,
    navigateToServicePage,
    navigateToBookPage,
  );

  const getCourseAvailability = createCourseAvailabilityAction({
    widgetViewModel,
    filteredResources,
    wixSdkAdapter,
    setProps,
    flowAPI,
  });

  const navigateToPricingPlan = createNavigateToPricingPlanAction(
    wixSdkAdapter,
    flowAPI,
  );

  const onPreferencesModalServiceSelected =
    createOnPreferencesModalServiceSelectedAction({
      flowAPI,
      widgetViewModel,
      setProps,
    });

  const navigateToNextPage = createNavigateToNextPageAction({
    wixSdkAdapter,
    flowAPI,
    config,
    widgetViewModel,
  });

  const openServicesPreferencesModal = createOpenServicesPreferencesModalAction(
    {
      setProps,
      widgetViewModel,
      wixSdkAdapter,
      flowAPI,
      config,
    },
  );

  const openPremiumDialog = createOpenPremiumDialogAction({
    setProps,
    widgetViewModel,
  });

  const onBookFlowStartAttempt = createOnBookFlowStartAttemptAction({
    wixSdkAdapter,
    flowAPI,
    bookingsApi,
    config,
    openServicesPreferencesModal,
    navigateToNextPage,
    openPremiumDialog,
  });

  const onServiceClick = createOnServiceClickAction({
    navigateToNextPage,
    flowAPI,
    widgetViewModel,
    setProps,
    onBookFlowStartAttempt,
  });

  const onMoreInfoClick = createOnMoreInfoClickAction({
    navigateToNextPage,
  });

  const onActionButtonClick = createOnActionButtonClickAction({
    navigateToNextPage,
    onBookFlowStartAttempt,
    widgetViewModel,
    setProps,
  });

  return {
    navigateToNextPage,
    onFilterOptionSelected,
    onDialogClose,
    navigateToServicePage,
    navigateToBookPage,
    secondaryNavigation,
    getCourseAvailability,
    navigateToPricingPlan,
    onPreferencesModalServiceSelected,
    onServiceClick,
    onMoreInfoClick,
    onActionButtonClick,
  };
};
