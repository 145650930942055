import { DialogType } from '../../viewModel/dialogViewModel/dialogViewModel';
import { WidgetViewModel } from '../../viewModel/viewModel';

export type OpenPremiumDialogAction = () => void;

export const createOpenPremiumDialogAction = ({
  widgetViewModel,
  setProps,
}: {
  widgetViewModel: WidgetViewModel;
  setProps: Function;
}) => {
  return () => {
    widgetViewModel.dialogViewModel.isOpen = true;
    widgetViewModel.dialogViewModel.type = DialogType.Premium;
    widgetViewModel.navigatingToService = '';
    setProps({
      widgetViewModel: {
        ...widgetViewModel,
      },
    });
  };
};
