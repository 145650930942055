import {
  ISettingsParamsBooleansKeys,
  ISettingsParamsCustomsKeys,
  ISettingsParamsTextKeys,
} from '../../components/BookOnline/settingsParams';
import {
  DisplayServicesByOptions,
  ServiceListLayoutOptions,
} from '../../types/types';
import { Experiments } from '@wix/yoshi-flow-editor';
import { IWixAPI } from '@wix/native-components-infra/dist/es/src/types/types';
import { isAnywhereUrl } from '@wix/wix-anywhere-api';
import { parse } from 'jsurl';

export type PublicDataOverrides = {
  displayServicesBy?: DisplayServicesByOptions;
  serviceListLayout?: ServiceListLayoutOptions;
  isTitleVisible?: boolean;
  services?: string[];
  categories?: string[];
  locations?: string[];
  titleText?: string;
};

export const mapPublicDataOverridesToPublicData = (
  publicDataOverrides?: string,
  serviceListTitle?: string,
): PublicDataOverrides & {
  titleText?: string;
} => {
  const publicData = {
    [ISettingsParamsTextKeys.titleText]: serviceListTitle,
  };
  if (!publicDataOverrides) {
    return publicData;
  }
  const publicDataOverridesFromUrl: PublicDataOverrides =
    parse(publicDataOverrides);
  return {
    ...(publicDataOverridesFromUrl.displayServicesBy && {
      [ISettingsParamsCustomsKeys.displayServicesBy]:
        publicDataOverridesFromUrl.displayServicesBy,
    }),
    ...(publicDataOverridesFromUrl.serviceListLayout && {
      [ISettingsParamsCustomsKeys.serviceListLayout]:
        publicDataOverridesFromUrl.serviceListLayout,
    }),
    ...('isTitleVisible' in publicDataOverridesFromUrl && {
      [ISettingsParamsBooleansKeys.isTitleVisible]:
        publicDataOverridesFromUrl.isTitleVisible,
    }),
    ...publicData,
  };
};

export const isAnywhereFlow = async (
  wixCodeApi: IWixAPI,
  experiments?: Experiments,
  isPreview?: boolean,
): Promise<boolean> => {
  if (isPreview) {
    return false;
  }
  const isAnywherePublicDataOverridesEnabled =
    experiments &&
    experiments.enabled('specs.bookings.anywherePublicDataOverrides');
  const isAnywhere = isAnywherePublicDataOverridesEnabled
    ? await isAnywhereUrl({
        fullUrl: wixCodeApi.location.url,
        externalBaseUrl: wixCodeApi.location.baseUrl,
      })
    : false;
  return isAnywhere || false;
};
