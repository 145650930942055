import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';

export type ResourceQueryParam = Partial<
  Record<
    BookingsQueryParams.STAFF | BookingsQueryParams.RESOURCE,
    string | undefined
  >
>;

export const getResourceQueryParam = (
  wixSdkAdapter: WixOOISDKAdapter,
): ResourceQueryParam => {
  const preSelectedStaff = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.STAFF,
  );
  if (preSelectedStaff) {
    return { [BookingsQueryParams.STAFF]: preSelectedStaff };
  }
  const preSelectedResource = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.RESOURCE,
  );
  if (preSelectedResource) {
    return { [BookingsQueryParams.RESOURCE]: preSelectedResource };
  }
  return {};
};
