import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { NavigationAction } from '../handleNavigation/handleNavigation';
import settingsParams from '../../components/BookOnline/settingsParams';
import { ServiceClickTarget } from '../../types/types';

export function createSecondaryNavigationAction(
  flowAPI: ControllerFlowAPI,
  navigateToServicePage: NavigationAction,
  navigateToBookPage: NavigationAction,
): NavigationAction {
  return (service, referralInfo) => {
    switch (flowAPI.settings.get(settingsParams.serviceClickTarget)) {
      case ServiceClickTarget.SERVICE_PAGE:
        navigateToServicePage(service, referralInfo);
        break;
      case ServiceClickTarget.BOOKING_CALENDAR:
        navigateToBookPage(service, referralInfo);
        break;
    }
  };
}
