import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { REQUESTED_CATEGORIES_URL_PARAM_NAME } from '../../consts';
import { getServices } from '../../viewModel/services/services';
import {
  FilterOption,
  FilterServicesByOptions,
  Resources,
} from '../../types/types';
import { WidgetViewModel } from '../../viewModel/viewModel';
import settingsParams from '../../components/BookOnline/settingsParams';
import { getCourseAvailability } from '../courseAvailability/courseAvailability';

export type OnFilterOptionSelectedAction = (
  selectedFilterOption: FilterOption,
) => void;

export const createOnFilterOptionSelectedAction = ({
  widgetViewModel,
  filteredResources,
  wixSdkAdapter,
  setProps,
  flowAPI,
}: {
  widgetViewModel: WidgetViewModel;
  filteredResources: Resources;
  wixSdkAdapter: WixOOISDKAdapter;
  setProps: Function;
  flowAPI: ControllerFlowAPI;
}): OnFilterOptionSelectedAction => {
  return async (selectedFilterOption: FilterOption) => {
    const { settings } = flowAPI;
    const filterServicesBy = settings.get(settingsParams.filterServicesBy);
    if (filterServicesBy === FilterServicesByOptions.CATEGORIES) {
      wixSdkAdapter.setUrlQueryParam(
        REQUESTED_CATEGORIES_URL_PARAM_NAME,
        selectedFilterOption.id,
      );
    }

    const newFilterOptions = widgetViewModel.filterOptions.map(
      (filterOption: FilterOption) => ({
        ...filterOption,
        isSelected: filterOption.id === selectedFilterOption.id,
      }),
    );

    const servicesToDisplay = await getServices({
      filterOptions: newFilterOptions,
      filteredResources,
      settings,
    });

    widgetViewModel.filterOptions = newFilterOptions;
    widgetViewModel.services = servicesToDisplay;
    widgetViewModel.coursesAvailability = await getCourseAvailability(
      flowAPI,
      filteredResources,
    );
    setProps({
      widgetViewModel: { ...widgetViewModel },
    });
  };
};
